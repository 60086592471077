import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { useDispatch, useSelector } from 'react-redux'
import SnackbarContentWrapper from './SnackbarContentWrapper'
import { closeSnackbar } from '../redux/notifications/actions'
import { makeStyles } from 'tss-react/mui'
import { ThemeProvider, useTheme } from '@mui/material'
import { createCustomTheme, darkTheme, lightTheme } from '../theme'
import { useRouter } from 'next/router'
import { getCurrentEvent } from 'selectors/event'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      marginTop: theme.spacing(4),
    },
    contentRoot: {
      flexWrap: 'unset',
    },
  }
})

const CoSnackbar = ({}) => {
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const router = useRouter()
  const themeQuery = router.query.theme
  const currentTheme = useTheme()

  const open = useSelector((state) => state.notifications.snackbar.open)
  const message = useSelector((state) => state.notifications.snackbar.message)
  const variant = useSelector((state) => state.notifications.snackbar.variant)
  const event = useSelector(getCurrentEvent)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(closeSnackbar())
  }

  // Create custom theme if event has a theme
  const customTheme = React.useMemo(() => {
    if (currentTheme.extras?.overrideAll) return currentTheme
    if (themeQuery === 'dark') return darkTheme
    if (themeQuery === 'light') return lightTheme
    if (!event?.theme) return lightTheme
    return createCustomTheme(event.theme)
  }, [event?.theme, themeQuery, currentTheme])

  return (
    <ThemeProvider theme={customTheme}>
      <Snackbar
        className={classes.root}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <div>
          <SnackbarContentWrapper
            className={classes.contentRoot}
            onClose={handleClose}
            variant={variant}
            message={message}
          />
        </div>
      </Snackbar>
    </ThemeProvider>
  )
}

export default CoSnackbar
