import {
  Button,
  Menu,
  MenuItem,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material'
import { DarkMode, LightMode, MoreVert, UnfoldMore } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { getIsSuperAdmin } from '../../selectors/event'
import React, { useEffect, useState } from 'react'
import Row from '../Row'
import ColourSchemeList from './ColourSchemeList'
import { fetchColourSchemes } from '../../actions/event'
import { useTranslation } from 'react-i18next'
import {
  createCustomTheme,
  darkTheme,
  lightTheme,
} from '../../shared-components/theme'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'

const AdminOverlay = ({ children }) => {
  const isAdmin = useSelector(getIsSuperAdmin)
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const originalTheme = useTheme()
  const colourSchemes = useSelector(
    (state: any) => state.event.colourSchemes?.list ?? [],
  )

  const [colourScheme, setColourScheme] = useState(null)
  const [darkMode, setDarkMode] = useState(originalTheme.extras.darkMode)
  const [activeTheme, setActiveTheme] = useState(originalTheme)
  const [open, setOpen] = useState(null)
  const [darkModeOpen, setDarkModeOpen] = useState(null)

  useEffect(() => {
    setActiveTheme(originalTheme)
  }, [originalTheme])

  const onSetColourScheme = (scheme) => {
    setColourScheme(scheme)
    setActiveTheme(
      scheme != null
        ? createCustomTheme(
            {
              colourScheme: scheme,
              typography: null,
              darkMode,
            },
            true,
          )
        : darkMode
          ? {
              ...darkTheme,
              extras: {
                ...darkTheme.extras,
                overrideAll: true,
              },
            }
          : {
              ...lightTheme,
              extras: {
                ...lightTheme.extras,
                overrideAll: true,
              },
            },
    )
  }

  const onSetDarkMode = (darkMode) => {
    setDarkMode(darkMode)
    setActiveTheme(
      colourScheme !== null
        ? createCustomTheme(
            {
              colourScheme,
              typography: null,
              darkMode,
            },
            true,
          )
        : darkMode
          ? {
              ...darkTheme,
              extras: {
                ...darkTheme.extras,
                overrideAll: true,
              },
            }
          : {
              ...lightTheme,
              extras: {
                ...lightTheme.extras,
                overrideAll: true,
              },
            },
    )
  }

  const onReset = () => {
    setActiveTheme(originalTheme)
  }

  const onCloseMenu = (e, reason) => {
    setOpen(null)
  }

  useEffect(() => {
    if (isAdmin && colourSchemes.length === 0) {
      dispatch(fetchColourSchemes())
    }
  }, [])

  return (
    <ThemeProvider theme={activeTheme}>
      <style jsx global>
        {`
          body {
            background: ${activeTheme.palette.background.default};
            color: ${activeTheme.palette.text.primary};
          }

          html {
            color-scheme: ${activeTheme.palette.mode === 'dark'
              ? 'dark'
              : 'light'};
          }
        `}
      </style>
      {isAdmin && (
        <Row
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
        >
          <Button
            sx={{
              color: activeTheme.palette.text.primary,
            }}
            onClick={(e) => setOpen(e.currentTarget)}
          >
            <MoreVert />
          </Button>

          <Menu
            disableScrollLock
            disableEnforceFocus
            disableEscapeKeyDown
            disableAutoFocus
            anchorEl={open}
            open={Boolean(open)}
            onClose={onCloseMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            sx={{
              mt: 1,
            }}
            slotProps={{
              paper: {
                sx: {
                  p: 0,
                },
              },
            }}
          >
            <Row
              sx={{
                px: 3,
                py: 2.5,
                rowGap: 2,
                columnGap: 7.5,
                flexWrap: 'wrap',
              }}
            >
              <Row
                sx={(theme) => ({
                  alignItems: 'center',
                  gap: 2,
                  [theme.breakpoints.down('md')]: {
                    alignItems: 'flex-start',
                  },
                })}
              >
                <Typography variant='body1Medium'>{t('theme')}</Typography>

                <ColourSchemeList
                  currentTheme={{
                    colourScheme,
                    darkMode,
                    typography: null,
                  }}
                  onSetColourScheme={onSetColourScheme}
                />
              </Row>

              <Row
                sx={{
                  display: 'flex',
                  gap: 3,
                  alignItems: 'center',
                }}
              >
                <Typography variant='body1Medium'>Mode</Typography>

                <Button
                  onClick={(e) => setDarkModeOpen(e.currentTarget)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.75,
                  }}
                >
                  {activeTheme.extras.darkMode ? (
                    <DarkMode fontSize='small' />
                  ) : (
                    <LightMode fontSize='small' />
                  )}
                  <Typography variant='body1'>
                    {t(
                      activeTheme.extras.darkMode ? 'themeDark' : 'themeLight',
                    )}
                  </Typography>
                  <UnfoldMore fontSize='small' />
                </Button>
                <Menu
                  anchorEl={darkModeOpen}
                  open={Boolean(darkModeOpen)}
                  onClose={() => setDarkModeOpen(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {[false, true].map((darkMode) => (
                    <MenuItem
                      // @ts-expect-error need to pass key
                      key={darkMode}
                      sx={{
                        gap: 1,
                      }}
                      onClick={() => {
                        onSetDarkMode(darkMode)
                        setDarkModeOpen(null)
                      }}
                    >
                      {darkMode ? <DarkMode /> : <LightMode />}
                      <Typography>
                        {t(darkMode ? 'themeDark' : 'themeLight')}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Row>

              <BashButton type={BashButtonType.PRIMARY_LIGHT} onClick={onReset}>
                Reset
              </BashButton>
            </Row>
          </Menu>
        </Row>
      )}
      {children}
    </ThemeProvider>
  )
}

export default AdminOverlay
