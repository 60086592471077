import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { Add } from '@mui/icons-material'
import mixpanel from '../../shared-components/utils/mixpanel'
import { resetDraft, setDraft, setLocBeforeEdit } from '../../actions/event'
import FlatButton from '../../shared-components/buttons/FlatButton'
import { setCurrentScrapeBotId } from 'actions/scraper'
import { getIsMobileOrTablet } from 'utils/isMobileOrTablet'
import { Tooltip } from '@mui/material'
import { keyframes } from '@emotion/react'
import { useUser } from '../../utils/userFunctions'

const gradientAnimation = keyframes`
  0% {
    background-position: 200% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

const useStyles = makeStyles()((theme) => ({
  root: {},
  menuItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  menuRoot: {
    width: '100%',
    maxWidth: '359px',
    [theme.breakpoints.down('md')]: {
      left: '0 !important',
    },
  },
  menuList: {
    padding: 0,
  },
  mainButton: {
    cursor: 'pointer',
    color: '#754AA1',
  },
  buttonText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19.09px',
    letterSpacing: '0.5px',
  },
  menuItemRoot: {
    padding: theme.spacing(1, 0, 1, 1),
    flexGrow: 1,
  },
  menuItemIcon: {
    marginRight: theme.spacing(2),
  },
  menuDivider: {
    width: '100%',
    height: 1,
    background: theme.palette.grey.main,
  },
  menuPrimaryItem: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  menuSecondaryContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  menuSecondaryItem: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    alignItems: 'center',
    padding: '9px 30px 9px 0px',
  },
  menuItemDivider: {
    width: '1px',
    height: '40px',
    background: theme.palette.grey.main,
    marginRight: '29px',
  },
  newEventButton: {
    height: '32px',
    padding: theme.spacing(1, 1.5),
    color: theme.palette.secondary[800],
    backgroundColor: theme.palette.background.paper,
  },
  mobileIcon: {
    color: theme.palette.grey[700],
    cursor: 'pointer',
  },
  '@keyframes gradient': {
    '0%, 100%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
  },
  gradientText: {
    background:
      'linear-gradient(90deg, #F33DF3 0%, #9B40F9 25%, #3D44F7 50%, #3F6AD4 75%, #F33DF3 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    WebkitTextFillColor: 'transparent',
    backgroundSize: '200% 200%',
    animation: `${gradientAnimation} 6s linear infinite`,
  },
  smallIcon: {
    width: 14,
    height: 14,
    color: theme.palette.secondary[800],
  },
  fancyStartIcon: {
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
    fontWeight: 500,
    fontSize: '12px',
    height: '14px',
  },
  shortcut: {
    marginLeft: 4,
    backgroundColor: theme.palette.grey[700],
    padding: '4px 4px',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const CreateButton = ({
  mobile = false,
  basic = false,
  className,
  fancy = false,
  openInNewTab = false,
  sx = [],
}) => {
  const { user } = useUser()
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const menuOpen = Boolean(menuAnchorEl)
  const router = useRouter()
  const dispatch = useDispatch()
  const draft = useSelector((state) => state.event.events.draft)
  const currentUser = useSelector((state) => state.event.currentSelectedUser)
  const orgUsername = router.query?.username
  const currentOrg = useSelector((state) => state.organisation.organisation)

  const onClick = (e) => {
    // TODO: Mixpanel
    onEventClick()
    // setMenuAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setMenuAnchorEl(null)
  }

  const onItemClick = (type) => {
    switch (type) {
      case 'EVENT':
        mixpanel.track('Start Throw Bash', {
          'Location in App': 'header',
          Path: window.location.pathname,
        })
        dispatch(resetDraft())
        changeDraft()
        dispatch(setLocBeforeEdit(router.asPath))
        dispatch(setCurrentScrapeBotId(null))
        if (openInNewTab) {
          window.open('/create', '_blank')
        } else {
          router.push('/create')
        }
        break
      case 'PAGE':
        mixpanel.track('Create Organisation', {
          'Location in App': 'header',
          Path: window.location.pathname,
        })
        if (openInNewTab) {
          window.open('/public/create', '_blank')
        } else {
          router.push('/public/create')
        }
        break
    }

    handleClose()
  }

  const changeDraft = () => {
    if (orgUsername && currentOrg.manager) {
      // Page
      dispatch(
        setDraft({
          ...draft,
          organisation: currentOrg,
          hosts: [
            {
              type: 'ORGANISATION',
              model: currentOrg,
              role: 'OWNER',
            },
          ],
          privacyType: currentOrg.privateProfile ? 'FOLLOWERS' : 'PUBLIC',
          madeChange: false,
        }),
      )
    } else if (currentUser?.id === 0) {
      // Personal account
      dispatch(
        setDraft({
          ...draft,
          type: 'INVITE',
          organisation: null,
          privacyType: 'PRIVATE',
          madeChange: false,
          hosts: [
            {
              type: 'USER',
              model: user,
              role: 'OWNER',
            },
          ],
        }),
      )
    } else {
      // Page
      dispatch(
        setDraft({
          ...draft,
          organisation: currentUser,
          hosts: [
            {
              type: 'ORGANISATION',
              model: currentUser,
              role: 'OWNER',
            },
          ],
          privacyType: currentUser.privateProfile ? 'FOLLOWERS' : 'PUBLIC',
          madeChange: false,
        }),
      )
    }
  }

  const onEventClick = () => onItemClick('EVENT')

  const keydownHandler = (e) => {
    if (
      e.target.tagName === 'INPUT' ||
      e.target.tagName === 'TEXTAREA' ||
      e.target.isContentEditable
    ) {
      return
    }
    if (
      (e.key === 'C' || e.key === 'c') &&
      !e.ctrlKey &&
      !e.metaKey &&
      !e.altKey
    ) {
      onEventClick()
    }
  }
  React.useEffect(() => {
    document.addEventListener('keydown', keydownHandler)
    return () => {
      document.removeEventListener('keydown', keydownHandler)
    }
  }, [])

  if (mobile || basic) {
    return (
      <Tooltip
        title={
          <div className={classes.tooltip}>
            {t('common:createEvent')}
            <div className={classes.shortcut}>{'C'}</div>
          </div>
        }
      >
        <Add
          style={{}}
          className={cx(classes.mobileIcon, className)}
          onClick={onClick}
        />
      </Tooltip>
    )
  }

  return (
    <FlatButton
      onClick={onClick}
      className={cx(classes.newEventButton, className, {
        [classes.gradientText]: fancy,
        [classes.fancyStartIcon]: fancy,
      })}
      sx={sx}
      startIcon={
        getIsMobileOrTablet() || fancy ? (
          false
        ) : (
          <Add
            className={cx({ [classes.smallIcon]: fancy })}
            color={fancy ? 'secondary' : 'inherit'}
          />
        )
      }
    >
      {fancy ? t('common:createYourOwnEventPage') : t('common:createEvent')}
    </FlatButton>
  )
}

export default CreateButton
