import React from 'react'
import { Box, SxProps } from '@mui/material'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { darken, lighten } from '@mui/material/styles'

// Convert MUI's lighten/darken output to hex
const toHexColor = (color) => {
  const rgb = color.match(/\d+/g)
  if (!rgb) return color
  const toHex = (n) => {
    const hex = Math.round(n).toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }
  return `#${toHex(rgb[0])}${toHex(rgb[1])}${toHex(rgb[2])}`
}

const adjustColorForContrast = (rgb) => {
  const calculateLuminance = (r, g, b) => {
    const [r_, g_, b_] = [r, g, b].map((c) => {
      c /= 255
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
    })
    return 0.2126 * r_ + 0.7152 * g_ + 0.0722 * b_
  }

  const contrastRatio = (bgLuminance, textLuminance) => {
    return (textLuminance + 0.05) / (bgLuminance + 0.05)
  }

  let [r, g, b] = rgb
  let luminance = calculateLuminance(r, g, b)
  let ratio = contrastRatio(luminance, 1)

  while (ratio < 2.5) {
    // Adjust the color (darken slightly)
    r = Math.max(0, r - 5)
    g = Math.max(0, g - 5)
    b = Math.max(0, b - 5)
    luminance = calculateLuminance(r, g, b)
    ratio = contrastRatio(luminance, 1)
  }

  // Convert RGB array back to hex
  const toHex = (n) => {
    const hex = Math.max(0, Math.min(255, Math.round(n))).toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

/**
 * Calculate the difference in hue between two hex color strings.
 * @param {string} hex1 - The first hex color string (e.g., "#FF5733").
 * @param {string} hex2 - The second hex color string (e.g., "#33FF57").
 * @returns {number} The absolute difference in hue (0-180).
 */
function hueDifference(hex1: string, hex2: string): number {
  // Convert a hex color to an RGB object
  function hexToRgb(hex) {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return { r, g, b }
  }

  // Convert RGB to HSL and return the hue
  function rgbToHue({ r, g, b }) {
    r /= 255
    g /= 255
    b /= 255
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h

    if (max === min) {
      h = 0 // Gray, no hue
    } else if (max === r) {
      h = ((g - b) / (max - min)) % 6
    } else if (max === g) {
      h = (b - r) / (max - min) + 2
    } else {
      h = (r - g) / (max - min) + 4
    }

    h = Math.round(h * 60) // Convert to degrees
    if (h < 0) h += 360 // Normalize
    return h
  }

  // Get the hue of each color
  const hue1 = rgbToHue(hexToRgb(hex1))
  const hue2 = rgbToHue(hexToRgb(hex2))

  // Calculate the absolute difference
  const diff = Math.abs(hue1 - hue2)
  return Math.min(diff, 360 - diff) // Account for hue wrapping
}

const ColourSchemeList = ({ currentTheme, onSetColourScheme }) => {
  const colourSchemes = useSelector(
    (state: any) => state.event.colourSchemes?.list ?? [],
  )

  const debouncedAdjustColor = debounce(async (hex) => {
    if (!hex) {
      onSetColourScheme(null)
      return
    }
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    const adjustedColor = adjustColorForContrast([r, g, b])

    // Calculate luminance of the selected color
    const calculateLuminance = (r, g, b) => {
      const [r_, g_, b_] = [r, g, b].map((c) => {
        c /= 255
        return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
      })
      return 0.2126 * r_ + 0.7152 * g_ + 0.0722 * b_
    }

    const luminance = calculateLuminance(r, g, b)

    // If the color is very dark (low luminance), use a lighter version for dark mode
    const darkModePrimaryColor =
      luminance < 0.2
        ? toHexColor(lighten(adjustedColor, 0.6)) // Make it significantly lighter for dark mode
        : adjustedColor

    const customTheme = {
      id: 0,
      // Light mode colors
      colorBackground: toHexColor(lighten(adjustedColor, 0.98)),
      colorTextPrimary: toHexColor(darken(adjustedColor, 0.8)),
      colorTextSecondary: toHexColor(darken(adjustedColor, 0.6)),
      colorButtonPrimary: adjustedColor,
      colorButtonSecondary: toHexColor(lighten(adjustedColor, 0.85)),
      // Dark mode colors
      colorDarkBackground: toHexColor(darken(adjustedColor, 0.88)),
      colorDarkTextPrimary: toHexColor(lighten(adjustedColor, 0.98)),
      colorDarkTextSecondary: toHexColor(lighten(adjustedColor, 0.85)),
      colorDarkButtonPrimary: darkModePrimaryColor, // Use the adjusted color for dark mode
      colorDarkButtonSecondary: toHexColor(darken(adjustedColor, 0.6)),
    }

    onSetColourScheme(customTheme)
  }, 100)

  const itemSx: SxProps = {
    width: 24,
    height: 24,
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'border 0.2s',
    '&:hover': {
      borderColor: '#2E60DC',
    },
    backgroundColor: 'white',
  }

  console.log(currentTheme.colourScheme)

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1.5,
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          ...itemSx,
          border: (theme) =>
            `1px solid ${
              theme.palette.mode === 'dark'
                ? !currentTheme.colourScheme
                  ? '#fff'
                  : '#424242'
                : !currentTheme.colourScheme
                  ? '#000'
                  : '#e0e0e0'
            }`,
        }}
        onClick={() => onSetColourScheme(null)}
        role='button'
        aria-label={'Remove theme'}
      />
      {colourSchemes.map((scheme) => (
        <Box
          key={scheme.id}
          sx={{
            ...itemSx,
            border: (theme) =>
              `1px solid ${
                theme.palette.mode === 'dark'
                  ? currentTheme.colourScheme?.id === scheme.id
                    ? '#fff'
                    : '#424242'
                  : currentTheme.colourScheme?.id === scheme.id
                    ? '#000'
                    : '#e0e0e0'
              }`,
            // backgroundColor: scheme.colorButtonPrimary,
            background:
              hueDifference(
                scheme?.colorButtonPrimary,
                scheme?.colorButtonSecondary,
              ) > 20
                ? `linear-gradient(to right, ${scheme?.colorButtonPrimary} 50%, ${scheme?.colorButtonSecondary} 50%)`
                : scheme.colorButtonPrimary,
            transform: 'rotate(-45deg)',
          }}
          onClick={() => onSetColourScheme(scheme)}
          role='button'
          aria-label={`Select ${scheme.id} theme`}
        />
      ))}
      <Box
        component='label'
        sx={{
          ...itemSx,
          border: (theme) =>
            `1px solid ${
              theme.palette.mode === 'dark'
                ? currentTheme.colourScheme?.id === 0
                  ? '#fff'
                  : '#424242'
                : currentTheme.colourScheme?.id === 0
                  ? '#000'
                  : '#e0e0e0'
            }`,
          background:
            currentTheme.colourScheme?.id === 0 ||
            currentTheme.colourScheme?.custom
              ? currentTheme.colourScheme?.colorButtonPrimary
              : 'transparent',
          position: 'relative',
          display: 'block',
        }}
      >
        {currentTheme.colourScheme?.id !== 0 &&
          currentTheme.colourScheme?.custom !== true && (
            <img
              src='/images/color-picker.png'
              alt='Color picker'
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          )}
        <input
          type='color'
          style={{ opacity: 0, width: 0, height: 0 }}
          onChange={(e) => debouncedAdjustColor(e.target.value)}
          aria-label='Custom color picker'
        />
      </Box>
    </Box>
  )
}

export default ColourSchemeList
