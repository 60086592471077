import React, { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import cn from 'clsx'

import {
  Badge,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  ArrowBack,
  MoreVert,
  Notifications,
  NotificationsOutlined,
  SearchOutlined,
} from '@mui/icons-material'
import getIndexFromPath from 'utils/getRouteIndex'
import FlatButton from 'shared-components/buttons/FlatButton'
import dynamic from 'next/dynamic'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSearch } from 'actions/modals'
import { useUser } from '../../utils/userFunctions'
import TextInput from './TextInput'
import CreateButton from '../../components/Navigation/CreateButton'
import { getCurrentEvent, getIsSuperAdmin } from '../../selectors/event'
import mixpanel from '../utils/mixpanel'
import { logoutUser } from '../../actions/user'
import Link from 'next/link'
import { useIsLoggedIn } from '../../utils/hooks'
import { useDownloadApp } from '../utils/downloadApp'
import Image from 'next/image'
import { openSignInModal } from '../../actions/modals'
import IconBoxed from '../../svg/logo/IconBoxed'
import BetaBadge from '../../components/EventCreation/BetaBadge'
import NotificationsContent from '../../components/Notifications/NotificationsContent'
import { paletteRed } from '../colors'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      '& a': {
        textDecoration: 'none',
        color: 'inherit',
        height: 18,
        width: 18,
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(4),
      },
      zIndex: '101',
      top: 0,
      transition: `max-width 1s ${theme.transitions.easing.easeInOut}`,
      [theme.breakpoints.down('lg')]: {
        '& a': {
          marginLeft: theme.spacing(2),
        },
      },
    },
    small: {
      maxWidth: theme.breakpoints.values.visual,
    },
    paper: {
      width: '100%',
      borderRadius: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    logo: {
      cursor: 'pointer',
      height: 18,
      width: 18,
      color: theme.palette.grey[700],
    },
    leftHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    rightHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      // '& > *': {
      //   marginRight: theme.spacing(1),
      // },
    },
    avatar: {
      cursor: 'pointer',
      borderRadius: 1000,
      width: 20,
      height: 20,
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('lg')]: {
        marginRight: theme.spacing(2),
      },
    },
    avatarSelected: {
      border: `1px solid ${theme.palette.red.main}`,
    },
    innerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      // maxWidth: theme.breakpoints.values.containerMaxWidth,
      height: '48px',
    },
    middleHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    searchBar: {
      background: theme.palette.grey[200],
      height: '30px',
      fontSize: '16px',
      padding: theme.spacing(0, 1),
      marginRight: theme.spacing(2),
      borderRadius: theme.spacing(2),
    },
    searchFullWidth: {
      width: '100%',
      marginRight: theme.spacing(1),
    },
    searchIcon: {
      color: theme.palette.text.secondary,
      height: '0.8em',
    },
    searchClose: {
      cursor: 'pointer',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    hidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      width: '1px',
    },
    notificationIcon: {
      color: theme.palette.grey[700],
      cursor: 'pointer',
      marginLeft: theme.spacing(-0.2),
    },
    badge: {
      backgroundColor: theme.extras.darkMode
        ? paletteRed.dark.main
        : paletteRed.light.main,
      cursor: 'pointer',
    },
    menuItem: {
      padding: theme.spacing(1, 2),
      border: 'none !important',
    },
    menuDivider: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: '100%',
      height: '1px',
      background: theme.palette.grey.main,
    },
    adminNavSelected: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey.main,
      borderRadius: theme.spacing(1),
    },
    useAppButton: {
      height: '32px',
      padding: theme.spacing(1, 1.5),
    },
    signInButton: {
      height: '32px',
      padding: theme.spacing(1, 0),
      backgroundColor: 'transparent',
    },
    marginRight2: {
      marginRight: theme.spacing(2),
    },
    myEvents: {
      height: 24,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      color: theme.palette.grey[700],
      cursor: 'pointer',
      marginRight: theme.spacing(1.5),
    },
    myEventsIcon: {
      fontSize: '24px',
    },
    forYou: {
      height: 24,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
      borderLeft: theme.palette.grey[200] + ' solid 1px',
      color: theme.palette.grey[700],
      cursor: 'pointer',
    },
    selected: {
      color: theme.palette.grey[800],
    },
    forYouContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    popoverPaper: {
      maxWidth: 480,
      width: '100%',
      height: 280,
    },
    grey: {
      color: theme.palette.grey[700],
    },
    shortcut: {
      marginLeft: 4,
      backgroundColor: theme.palette.grey[700],
      padding: '6px',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tooltip: {
      display: 'flex',
      alignItems: 'center',
    },
  }
})

const DynamicSearchResults = dynamic(
  () => import('components/Search/SearchResults'),
)
// const DynamicNotifications = dynamic(
//   () => import('components/Notifications/Notifications'),
// )

const Header = ({ t, userAvatarUrls, signIn, signOut, toProfile, small }) => {
  const { classes, cx } = useStyles()
  const router = useRouter()
  const dispatch = useDispatch()
  const { user } = useUser()
  const event = useSelector(getCurrentEvent)
  const isSuperAdmin = useSelector(getIsSuperAdmin)

  const [value, setValue] = React.useState(0)
  const [query, setQuery] = React.useState('')
  const searchRef = useRef(null)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.up('md'))
  const desktop = useMediaQuery(theme.breakpoints.up('md'))
  const isLoggedIn = useIsLoggedIn()

  const focusedSearch = useSelector((state) => state.modals.search)

  const unreadNotifications = useSelector(
    (state) => state.user?.user?.unreadNotifications,
  )

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElSignIn, setAnchorElSignIn] = useState(null)

  const [notifAnchor, setNotifAnchor] = useState(null)
  const [notifTooltipOpen, setNotifTooltipOpen] = useState(false)
  const [searchTooltipOpen, setSearchTooltipOpen] = useState(false)
  const notifRef = useRef(null)

  const { onDownloadClick, QRCodeModal, downloadUrl } = useDownloadApp({
    utm_medium: 'Header',
    redirectPath: router.asPath,
    usingHref: false,
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickSignIn = (event) => {
    setAnchorElSignIn(event.currentTarget)
  }

  const handleCloseSignIn = () => {
    setAnchorElSignIn(null)
  }

  if (typeof window !== 'undefined') {
    router.events.on('routeChangeComplete', (url) => {
      if (focusedSearch || notifAnchor) {
        dispatch(toggleSearch(false))
        setNotifAnchor(null)
      }
    })
  }

  useEffect(() => {
    setValue(getIndexFromPath(router.pathname))
  }, [router.pathname])

  const handleQuery = (event) => {
    setQuery(event.target.value)
  }
  const onBlur = () => {
    dispatch(toggleSearch(false))
    setNotifAnchor(null)
    searchRef.current?.blur()
  }
  const onFocus = () => {
    dispatch(toggleSearch(true))
    setNotifAnchor(null)
  }
  useEffect(() => {
    if (focusedSearch) {
      searchRef.current && searchRef.current.focus()
    }
  }, [focusedSearch])

  const keydownHandler = (e) => {
    if (e.key === 'Escape') {
      if (query === '') {
        onBlur()
      }
    } else {
      if (
        e.target.tagName === 'INPUT' ||
        e.target.tagName === 'TEXTAREA' ||
        e.target.isContentEditable
      ) {
        return
      }
      if (e.key === '/' && !e.ctrlKey && !e.metaKey && !e.altKey) {
        e.preventDefault()
        onFocus()
      }
      if (
        (e.key === 'a' || e.key === 'A') &&
        !e.ctrlKey &&
        !e.metaKey &&
        !e.altKey
      ) {
        onClickNotifications()
      }
      if (
        (e.key === 'f' || e.key === 'F') &&
        !e.ctrlKey &&
        !e.metaKey &&
        !e.altKey
      ) {
        router.push('/foryou')
      }
      if (
        (e.key === 'h' || e.key === 'H') &&
        !e.ctrlKey &&
        !e.metaKey &&
        !e.altKey
      ) {
        router.push('/home')
      }
    }
  }
  React.useEffect(() => {
    document.addEventListener('keydown', keydownHandler)
    return () => {
      document.removeEventListener('keydown', keydownHandler)
    }
  }, [])

  const onClickNotifications = () => {
    setNotifAnchor(notifRef.current)
    dispatch(toggleSearch(false))
  }

  const menuClick = (item) => {
    switch (item) {
      case 'profile':
        router.push(`/users/${user.id}`)
        break
      case 'details':
        router.push('/me/settings/details')
        break
      case 'notifications':
        router.push('/me/settings/notifications')
        break
      case 'support':
        mixpanel.track('Click Support', { 'Location in App': 'header' })
        window.open('https://wa.me/message/4GG2EGM4GIDBD1')
        break
      case 'signOut':
        dispatch(logoutUser())
        break
    }
    handleClose()
  }

  const selectedSearchAndSmall = !fullScreen && focusedSearch

  const pathname = router.pathname
  const fromUserCode = useSelector((state) => state.user.fromUserCode)
  const publicAndNotLoggedIn =
    fromUserCode &&
    !desktop &&
    !isLoggedIn &&
    event.privacyType === 'PUBLIC' &&
    (pathname.startsWith('/e/') || pathname.startsWith('/events/'))

  if (
    publicAndNotLoggedIn ||
    pathname === '/events/create' ||
    pathname === '/edit' ||
    pathname === '/create' ||
    pathname.startsWith('/edit/') ||
    pathname.startsWith('/create') ||
    pathname === '/profile' ||
    pathname.startsWith('/profile/') ||
    pathname === '/about' ||
    pathname === '/press' ||
    pathname === '/newhome' ||
    pathname === '/kratje' ||
    pathname === '/stelzdelivery' ||
    pathname === '/newIndex' ||
    pathname.startsWith('/m/') ||
    pathname.startsWith('/i/') ||
    pathname === '/signIn' ||
    pathname === '/public/create' ||
    pathname === '/pages' ||
    pathname.endsWith('/chat') ||
    pathname.endsWith('/widget') ||
    pathname.endsWith('/widget-new') ||
    pathname.startsWith('/newsletter/') ||
    pathname.endsWith('/tickets') ||
    pathname.endsWith('/checkout') ||
    pathname.endsWith('/posts') ||
    pathname.startsWith('/devApp') ||
    ((pathname === '/home' || pathname === '/' || pathname === '') && !user.id)
  ) {
    return null
  }

  // if (!desktop && pathname.startsWith('/e/') && !user.id) return null

  const adminArea = pathname.startsWith('/admin/')

  const isForYou = pathname === '/foryou'

  const isOrgPage = pathname === '/[username]'
  const isEventPage =
    pathname === '/e/[eventCode]/[[...param]]' ||
    pathname === '/events/[eventId]/[[...param]]'
  const onlySimple = (isOrgPage || isEventPage) && !isLoggedIn

  if (adminArea && pathname.endsWith('/batch')) {
    return null
  }

  return (
    <Box
      component='header'
      className={cx(classes.root, { [classes.small]: small })}
      id='bash-app-header'
      sx={(theme) => ({
        position: isForYou ? 'sticky' : 'inherit',
        background: isForYou ? theme.palette.background.default : undefined,
        borderBottom: isForYou
          ? theme.palette.grey.main + ' solid 1px'
          : undefined,
      })}
    >
      <QRCodeModal />
      <div
        className={classes.paper}
        style={{
          borderBottom: onlySimple ? 'none' : undefined,
        }}
      >
        {focusedSearch && (
          <DynamicSearchResults
            searchRef={searchRef}
            focusedSearch={focusedSearch}
            query={query}
            closeModal={onBlur}
          />
        )}
        <div className={classes.innerHeader}>
          <div className={classes.leftHeader}>
            {selectedSearchAndSmall ? (
              <ArrowBack className={classes.searchClose} onClick={onBlur} />
            ) : (
              <>
                {isLoggedIn && desktop ? (
                  <Tooltip
                    title={
                      <div className={classes.tooltip}>
                        {t('common:myEvents')}
                        <div className={classes.shortcut}>{'H'}</div>
                      </div>
                    }
                  >
                    <Box
                      onClick={() => router.push('/home')}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        gap: 1,
                        ml: 2,
                      }}
                    >
                      <IconBoxed
                        className={cx(classes.logo, {
                          [classes.singleLogoMargin]: !userAvatarUrls,
                          [classes.selected]:
                            pathname === '/home' || pathname === '/',
                        })}
                        alt='logo'
                      />
                      <Typography
                        className={cn(classes.myEvents, {
                          [classes.selected]:
                            pathname === '/home' || pathname === '/',
                        })}
                        variant='button'
                      >
                        {t('common:myEvents')}
                      </Typography>
                    </Box>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <div className={classes.tooltip}>
                        {isLoggedIn ? t('common:myEvents') : 'Home'}
                        {isLoggedIn && (
                          <div className={classes.shortcut}>{'H'}</div>
                        )}
                      </div>
                    }
                  >
                    <Link passHref href='/home' aria-label='Home button'>
                      <IconBoxed
                        className={cx(classes.logo, {
                          [classes.singleLogoMargin]: !userAvatarUrls,
                          [classes.selected]:
                            pathname === '/home' || pathname === '/',
                        })}
                        alt='logo'
                      />
                    </Link>
                  </Tooltip>
                )}

                {!onlySimple && (
                  <Tooltip
                    title={
                      <div className={classes.tooltip}>
                        {'For you'}
                        <div className={classes.shortcut}>{'F'}</div>
                      </div>
                    }
                  >
                    <div
                      className={classes.forYouContainer}
                      onClick={() => router.push('/foryou')}
                    >
                      <Typography
                        className={cn(classes.forYou, {
                          [classes.selected]: pathname === '/foryou',
                        })}
                        variant='button'
                      >
                        {'For you'}
                      </Typography>

                      {isForYou && <BetaBadge />}
                    </div>
                  </Tooltip>
                )}
              </>
            )}
          </div>
          {!onlySimple && (
            <div
              className={cx(classes.middleHeader, {
                [classes.searchFullWidth]: selectedSearchAndSmall,
              })}
            >
              {focusedSearch && !adminArea && (
                <>
                  <label className={classes.hidden} htmlFor='search-field'>
                    Search
                  </label>
                  <TextInput
                    className={cx(classes.searchBar, {
                      [classes.searchFullWidth]: selectedSearchAndSmall,
                    })}
                    id='search-field'
                    placeholder={t('searchOnBASH')}
                    type='search'
                    size='small'
                    aria-labelledby='search-field'
                    aria-label='Search'
                    variant='outlined'
                    onChange={handleQuery}
                    onFocus={onFocus}
                    autoComplete='off'
                    startAdornment={
                      <InputAdornment position='start'>
                        <SearchOutlined className={classes.searchIcon} />
                      </InputAdornment>
                    }
                    inputRef={searchRef}
                  />
                </>
              )}
            </div>
          )}
          <div className={classes.rightHeader}>
            <>
              {!selectedSearchAndSmall && (
                <>
                  {adminArea && (
                    <>
                      <Button
                        onClick={() => {
                          if (pathname === '/admin/stats') return
                          router.push('/admin/stats')
                        }}
                        className={cx(classes.adminNav, {
                          [classes.adminNavSelected]:
                            pathname === '/admin/stats',
                        })}
                      >
                        <Typography variant='button'>Stats</Typography>
                      </Button>
                      <Button
                        onClick={() => {
                          if (pathname === '/admin/pages') return
                          router.push('/admin/pages')
                        }}
                        className={cx(classes.adminNav, {
                          [classes.adminNavSelected]:
                            pathname === '/admin/pages',
                        })}
                      >
                        <Typography variant='button'>Scrapers</Typography>
                      </Button>
                      <Button
                        onClick={() => {
                          if (pathname === '/admin/review/next') return
                          router.push('/admin/review/next')
                        }}
                        className={cx(classes.adminNav, {
                          [classes.adminNavSelected]:
                            pathname === '/admin/review/next',
                        })}
                      >
                        <Typography variant='button'>Review</Typography>
                      </Button>
                    </>
                  )}

                  {!adminArea && !onlySimple && (
                    <>
                      {isLoggedIn && (
                        <>
                          <div style={{ width: 24, height: 24 }}>
                            <CreateButton mobile={!desktop} basic={true} />
                          </div>
                          <Tooltip
                            open={searchTooltipOpen}
                            onOpen={() => setSearchTooltipOpen(true)}
                            onClose={() => setSearchTooltipOpen(false)}
                            title={
                              <div className={classes.tooltip}>
                                {t('common:search')}
                                <div className={classes.shortcut}>{'/'}</div>
                                {/*<span style={{ marginLeft: '4px' }}>*/}
                                {/*  {t('or')}*/}
                                {/*</span>*/}
                                {/*<div className={classes.shortcut}>{'S'}</div>*/}
                              </div>
                            }
                          >
                            <SearchOutlined
                              style={{ cursor: 'pointer' }}
                              onClick={onFocus}
                              className={classes.grey}
                            />
                          </Tooltip>
                        </>
                      )}
                    </>
                  )}

                  {isLoggedIn && (
                    <>
                      <Tooltip
                        open={notifTooltipOpen && !notifAnchor}
                        onOpen={() => setNotifTooltipOpen(true)}
                        onClose={() => setNotifTooltipOpen(false)}
                        title={
                          <div className={classes.tooltip}>
                            {t('activity')}
                            <div className={classes.shortcut}>{'A'}</div>
                          </div>
                        }
                      >
                        <Badge
                          classes={{
                            colorPrimary: classes.badge,
                          }}
                          variant='dot'
                          color='primary'
                          invisible={unreadNotifications < 1}
                          overlap='circular'
                          // badgeContent={unreadNotifications}
                          onClick={() => {
                            onClickNotifications()
                          }}
                          ref={notifRef}
                        >
                          {!!notifAnchor && (
                            <Notifications
                              className={cx(
                                classes.notificationIcon,
                                classes.notificationIconSelected,
                              )}
                            />
                          )}
                          {!notifAnchor && (
                            <NotificationsOutlined
                              className={classes.notificationIcon}
                            />
                          )}
                        </Badge>
                      </Tooltip>

                      {/* <Backdrop
                        style={{
                          zIndex: 1000,
                          backgroundColor: theme.palette.background['backdrop'],
                        }}
                        open={!!notifAnchor}
                      /> */}
                      <Popover
                        open={Boolean(notifAnchor)}
                        anchorEl={notifAnchor}
                        onClose={() => {
                          setNotifAnchor(null)
                          setNotifTooltipOpen(false)
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        classes={{
                          paper: classes.popoverPaper,
                        }}
                      >
                        <NotificationsContent />
                      </Popover>

                      <Image
                        src={user.avatarUrls.sm}
                        width={20}
                        height={20}
                        onClick={handleClick}
                        alt='Profile picture'
                        className={cx(classes.avatar, {
                          [classes.avatarSelected]: value === 2,
                        })}
                      />
                      <Menu
                        id='profile-menu'
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        disableAutoFocusItem
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem
                          onClick={() => menuClick('profile')}
                          className={classes.menuItem}
                        >
                          <Typography variant='body2'>
                            {t('common:myProfile')}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => menuClick('details')}
                          className={classes.menuItem}
                        >
                          <Typography variant='body2'>
                            {t('common:settings.title')}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => menuClick('notifications')}
                          className={classes.menuItem}
                        >
                          <Typography variant='body2'>
                            {t('common:notificationSettings')}
                          </Typography>
                        </MenuItem>
                        <div className={classes.menuDivider} />
                        <MenuItem
                          onClick={() => menuClick('support')}
                          className={classes.menuItem}
                        >
                          <Typography variant='body2'>
                            {t('common:profileSettings.support')}
                          </Typography>
                        </MenuItem>
                        <div className={classes.menuDivider} />
                        <MenuItem
                          onClick={() => menuClick('signOut')}
                          className={classes.menuItem}
                        >
                          <Typography variant='body2'>
                            {t('common:profileSettings.signOut')}
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </>
              )}
            </>
            {!isLoggedIn && desktop && (
              <>
                <FlatButton
                  onClick={() =>
                    dispatch(openSignInModal(null, null, false, null, true))
                  }
                  className={cn(classes.signInButton, classes.marginRight2, {
                    [classes.grey]: true,
                  })}
                >
                  {t('common:signIn')}
                </FlatButton>
              </>
            )}
            {!desktop && !isLoggedIn && (
              <>
                <IconButton onClick={handleClickSignIn} size='large'>
                  <MoreVert className={classes.grey} />
                </IconButton>
                <Menu
                  open={!!anchorElSignIn}
                  anchorEl={anchorElSignIn}
                  onClose={handleCloseSignIn}
                >
                  <MenuItem
                    onClick={() =>
                      dispatch(openSignInModal(null, null, false, null, true))
                    }
                  >
                    <Typography variant='body2'>
                      {t('common:signIn')}
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </div>
      </div>
    </Box>
  )
}

export default withTranslation('shared')(Header)
